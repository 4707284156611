import { useState, useEffect } from "react";
import { MoonIcon, SunIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import NavbarButton from './NavbarButton'
import { ABOUT, EXPERIENCE, CONTACT, DARK_TEXT_ACCENT_CLASS, LIGHT_TEXT_ACCENT_CLASS } from '../constants'

import lightModeLogo from "../assets/light_mode_logo.png";
import darkModeLogo from "../assets/dark_mode_logo.png";

type NavbarProps = {
  dark: boolean;
  selectedLink: string;
  darkModeHandler: () => void;
  setSelectedLink: (linkName: string) => void;
}

function Navbar({ dark, selectedLink, darkModeHandler, setSelectedLink }: NavbarProps) {
  const links = [ABOUT, EXPERIENCE, CONTACT]
  const [loaded, setLoaded] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 150)
  }, [])

  const logoSrc = dark ? darkModeLogo : lightModeLogo;

  return (
    <>
    <nav
      className={`sticky top-4 z-50 block w-full max-w-full px-4 py-2 mt-4 text-black dark:text-white bg-white/85 dark:bg-slate-800/85 border shadow-md md:h-max border-white/80 dark:border-slate-900/10 lg:px-8 lg:py-4 rounded-xl [transition:transform_300ms,background-color_150ms,border_150ms] -translate-y-40 ${loaded && "translate-y-0"}`}>
      <div className="flex items-center justify-between text-blue-gray-900">
        <img src={logoSrc} alt="Aaron Domingo" className="h-16"/>
        <div className="flex items-center gap-4">
          <div className="hidden md:block">
            <ul className="flex flex-col gap-2 md:mb-0 md:mt-0 md:flex-row md:items-center md:gap-6">
              {
                links.map(link => <NavbarButton key={link} linkName={link} selectedLink={selectedLink} setSelectedLink={setSelectedLink} dark={dark} />)
              }
            </ul>
          </div>
          <button
            className="block p-1 font-sans text-sm antialiased font-normal leading-normal size-8 md:hidden"
            type="button">
            {
              openDrawer
                ? <XMarkIcon className="text-black dark:text-white animate-fade-in" onClick={() => setOpenDrawer(false)} />
                : <Bars3Icon className="text-black dark:text-white animate-fade-in" onClick={() => setOpenDrawer(true)} />
            }
          </button>
          <button className={`block p-1 font-sans text-sm antialiased font-normal leading-normal size-8 md:hover:scale-125 md:hover:-translate-y-1 md:active:scale-100 md:active:translate-y-0 transition-all duration-150 ${LIGHT_TEXT_ACCENT_CLASS} ${DARK_TEXT_ACCENT_CLASS}`} onClick={() => darkModeHandler()}>
            <span className="hidden md:block">
              {
              dark ? <SunIcon /> : <MoonIcon/>
              }
            </span>
            <span className="block md:hidden">
              {
              dark ? <SunIcon className="animate-fade-in" /> : <MoonIcon className="animate-fade-in"/>
              }
            </span>
          </button>
        </div>
      </div>
    </nav>
    {
        <div className={`md:hidden fixed top-[7.1rem] z-50 px-4 right-4 text-black dark:text-white bg-white/85 dark:bg-slate-800/85 shadow-md md:h-max rounded-xl [transition:background-color_150ms,opacity_150ms] ${openDrawer ? "opacity-100 visible" : "opacity-0 invisible"}`}>
          <div className="w-16 overflow-hidden inline-block absolute -top-4 right-7">
            <div className="h-4 w-4 bg-white/85 dark:bg-slate-800/85 rotate-45 transform origin-bottom-left [transition:background-color_150ms]"></div>
          </div>
          <ul className="flex flex-col align-center items-center gap-2 md:gap-6 py-4">
            {
              links.map(link => <NavbarButton key={link} linkName={link} selectedLink={selectedLink} setSelectedLink={setSelectedLink} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} dark={dark} withIcon />)
            }
          </ul>
        </div>
    }
    </>
  )
}

export default Navbar
