import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import BaseCard from "./BaseCard";
import Divider from '../common/Divider';
import ExperienceJobCard from "../common/ExperienceJobCard";
import SectionHeader from '../common/SectionHeader';

type ExperienceCardProps = {
  dark: boolean;
}

function ExperienceCard({ dark }: ExperienceCardProps) {
  const lineColor = dark ? "rgb(107 114 128)" : "rgb(226 232 240)";

  return (
    <BaseCard>
      <div className="bg-white/85 dark:bg-slate-800/85 duration-150 rounded-xl p-4">
        <SectionHeader title="WHAT I'VE BEEN UP TO" />
        <div className="px-2 py-2">
          <VerticalTimeline
            lineColor={lineColor}
            className="!w-full duration-150">
              <ExperienceJobCard
              dark={dark}
              isAccent
              date="2024 - Present"
              companyName="Babylist"
              jobTitle="Software Engineer III"
              jobLocation='Remote'
              responsibilities={[]}
              accomplishments={[]}
              />
            <ExperienceJobCard
              dark={dark}
              isAccent={false}
              date="2022 - 2024"
              companyName="Handshake"
              jobTitle="Software Engineer II"
              jobLocation='Remote'
              responsibilities={
                [
                  "Engaged in full-stack product development of Handshake’s main web application using React, Typescript, Ruby on Rails and PostgreSQL",
                  "Built performant REST API endpoints & GraphQL graphs that leverage existing company services like PubSub, ElasticSearch, and Sidekiq workers",
                  "Served as a DRI for multiple projects in an AGILE environment, collaborating with product, design, and key stakeholders to deliver high quality features",
                  "Implemented rigorous unit tests and system specs using RSpec, Capybara, React testing library, and Jest to enhance software quality and reliability"
                ]
              }
              accomplishments={
                [
                  "Lead the backend development of an iOS app using GraphQL & Elasticsearch",
                  "Chosen as an on-site transition SME in Berlin to help with knowledge transfer",
                  "Developed a free trial feature of a self service product via stripe that increased adoption rate by 88%",
                  "Received a performance rating that exceeded expectations every review cycle"
                ]
              }
              />
            <ExperienceJobCard
              dark={dark}
              isAccent
              date="2021 - 2022"
              companyName="Codazen"
              jobTitle="Software Engineer"
              jobLocation='Remote'
              responsibilities={
                [
                  "Created reusable functional components rendered within Meta’s internal CMS Tool as XML using JavaScript, Flow, React, Hack, and XHP",
                  "Maneuvered Meta’s internal CMS application to create pixel-perfect & responsive marketing pages for specific products such as Oculus and Portal",
                  "Implemented automated backend pipelines using Python and Hack to process and update data schemas via Meta’s internal CRON service",
                ]
              }
              accomplishments={
                [
                  "Owned and lead product launches with detailed documentation via runbooks",
                  "Assigned the role of Rapid Response engineer because of a successful track record",
                  "Incorporated analytics and template components to be reused across the CMS"
                ]
              }
            />
            <ExperienceJobCard
              dark={dark}
              isAccent={false}
              date="2020 - 2021"
              companyName="Green Street"
              jobTitle="Software Engineer"
              jobLocation='Remote'
              responsibilities={
                [
                  "Maintained the full-stack development of an internal data management tool with React, TypeScript, Node.Js, PostgreSQL, and TypeORM",
                  "Engaged in the full-stack development of an internal CMS application using Vue, JavaScript, Python, Flask, MySQL, and AWS Lambda",
                  "Improved and streamlined application features utilizing AWS (S3, SNS, CloudWatch, Lambda, API Gateway, ElasticSearch)"
                ]
              }
              accomplishments={
                [
                  "Integrated Auth0 with Okta’s SSO for user creation / login / roles",
                  "Built the entirety of the front end of a CMS application based on wireframes",
                  "Developed an Elasticsearch powered search service that allow users to easily look for and filter specific data from over 100,000+ records"
                ]
              }
            />
            <ExperienceJobCard
              dark={dark}
              isAccent
              date="2019 - 2020"
              companyName="JLM"
              jobTitle="Software Engineer"
              jobLocation='Long Beach, CA'
              responsibilities={
                [
                  "Engaged in full-stack development building and maintaining internal / external applications using JavaScript and Python technologies",
                  "Integrated JavaScript Frameworks (React & Vue) with CSS libraries (MaterialUI, Ant Design), utilizing Redux and Vuex for state management",
                  "Built REST API endpoints using Node and Flask servers complete with authentication using Json Web Tokens and Passport Sessions"
                ]
              }
              accomplishments={
                [
                  "Implemented a log middleware that allowed for the tracking of user activity",
                  "Incorporated webhooks via AWS Lambda for Asana, Workable, and Acuity APIs",
                  "Developed a live notification system using Flask-Socket.IO web socket"
                ]
              }
            />
          </VerticalTimeline>
        </div>
        <Divider />
        <div className="text-center text-lg font-semibold my-4">
          <span className="text-black dark:text-white">Want to see more? Download my full </span>
          <a className="underline text-blue-500 dark:text-yellow-500" target="_blank" href="https://drive.google.com/file/d/1_lQwtHNZbuBtH-rU9bZ35dnGf0bcXXoB/view" rel="noreferrer"> resume </a>
        </div>
      </div>
    </BaseCard>
  )
}

export default ExperienceCard
