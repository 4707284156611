import { useEffect } from "react";
import { useTypewriter } from 'react-simple-typewriter'

import { DARK_GRADIENT_ACCENT_CLASS, LIGHT_GRADIENT_ACCENT_CLASS } from "../constants";
import BaseCard from "./BaseCard";

import dayBanner from "../assets/day.jpg";
import nightBanner from "../assets/night.jpg";
import headshot from "../assets/headshot.jpg";
import sunglasses from "../assets/sunglasses.png";
import SectionHeader from "../common/SectionHeader";
import SectionBody from "../common/SectionBody";

type AboutCardProps = {
  dark: boolean;
  typeWriterCompleted: boolean;
  setTypeWriterToCompleted: () => void;
}

function AboutCard({ dark, typeWriterCompleted, setTypeWriterToCompleted }: AboutCardProps) {
  const avatarGradientClass = dark ? DARK_GRADIENT_ACCENT_CLASS : LIGHT_GRADIENT_ACCENT_CLASS;

  const [ text ] = useTypewriter({
    words: ["Problem Solver", "Detail Oriented", "Team Player", "Software Engineer"],
    loop: 1,
    typeSpeed: 100,
    deleteSpeed: 50,
    delaySpeed: 1500
  })

  useEffect(() => {
    if (text === "Software Engineer") {
      setTypeWriterToCompleted();
    }
  }, [text, setTypeWriterToCompleted])

  return (
    <BaseCard>
      <div className="bg-white/85 dark:bg-slate-800/85 duration-150 rounded-xl">
        <img className="object-cover object-center w-full h-64 rounded-t-xl" src={dark ? nightBanner : dayBanner} alt='banner' />
        <div className={`mx-auto w-64 h-64 relative -mt-32 overflow-hidden`}>
          <div className={`w-64 h-64 border-transparent ${avatarGradientClass} rounded-full animate-custom-spin`} />
          <img src={sunglasses} alt="sunglasses" className="absolute w-[5.75rem] left-[32%] top-[25%] dark:top-[35%] opacity-0 dark:opacity-100 duration-150 z-20" />
          <img src={headshot} alt='Headshot' className="w-64 h-64 border-4 border-transparent absolute top-0 rounded-full bg-cove z-10" />w-64 h-64
        </div>
        <div className="text-center mt-2">
          <h2 className="font-semibold text-4xl leading-tight text-black dark:text-white tracking-wide duration-150">AARON DOMINGO</h2>
          <div className="text-3xl text-gray-500 dark:text-slate-400 leading-relaxed duration-150 min-h-[48.75px]">
            {
              typeWriterCompleted
                ? (
                  <>
                    Software Engineer
                  </>
                )
                : (
                  <>
                  {text}
                  </>
                )
            }
          </div>
        </div>
        <div className="px-4 pb-4">
          <SectionHeader title="HELLO!" />
          <SectionBody text="I am a software engineer based in Orange County, California. Over the past five years, I have had the privilege of working with various tech stacks, including JavaScript, TypeScript, Python, and Ruby, and have contributed to the full-stack development of numerous consumer-grade applications. With a strong track record in all my roles, I am confident in my ability to make a significant impact on any team. Currently, I am employed as a Software Engineer III and look forward to further advancing my career and skills as a developer. I am always eager to learn new languages, frameworks, and technologies!" />
          <SectionBody text="Outside of coding, you’ll often find me hiking with my Jindo &#128021;, working out at the gym &#128170;, or climbing the ranks in various competitive games &#128187;." />
        </div>
      </div>
    </BaseCard>
  )
}

export default AboutCard
